var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{attrs:{"role":"main"}},[_c('meta-title',{attrs:{"title":_vm.metaTitle}}),_c('section',{staticClass:"section section-route"}),_c('section',{staticClass:"section-first-page-hero"},[_c('slider')],1),_c('section',{staticClass:"section section-services"},[_c('div',{staticClass:"container m-container-only-s"},[_c('div',{staticClass:"items"},_vm._l((_vm.layoutComp),function(item,index){return _c('div',{key:index,staticClass:"item",class:{
              active: _vm.orders.find(function (order) { return order.items.find(function (it) { return it.id === item.id; }); }
              ),
              waiting: _vm.waiting.find(function (order) { return +order.id === +item.id; })
            },on:{"click":function($event){$event.preventDefault();return _vm.setOrderAction(item)}}},[(
                _vm.orders.find(function (order) { return order.items.find(function (it) { return it.id === item.id; }); })
              )?_c('i'):_vm._e(),_c('div',{staticClass:"grid"},[_c('h3',[_vm._v(_vm._s(item.title))]),_c('img',{attrs:{"src":_vm.replaceCoverImage(item.image),"alt":""}}),(
                  _vm.orders.find(function (order) { return order.items.find(function (it) { return it.id === item.id; }); }
                  )
                )?_c('p',[_vm._v(" "+_vm._s(_vm.$t("services.orderMade"))+" ")]):_c('p',[_vm._v(" "+_vm._s(item.subTitle)+" ")]),(
                  _vm.orders.find(function (order) { return order.items.find(function (it) { return it.id === item.id; }); }
                  )
                )?_c('button',{on:{"click":function($event){$event.preventDefault();return _vm.cancelOrder({
                    id: item.id
                  })}}},[_vm._v(" "+_vm._s(_vm.$t("services.cancel"))+" ")]):_vm._e()])])}),0)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }