<template>
  <main role="main">
    <meta-title :title="metaTitle"></meta-title>
    <section class="section section-route">
<!--      <RouteBar></RouteBar>-->
    </section>
    <section class="section-first-page-hero">
      <slider></slider>
    </section>
    <section class="section section-services">
      <div class="container m-container-only-s">
        <div class="items">
          <div
            v-for="(item, index) in layoutComp"
            :key="index"
            @click.prevent="setOrderAction(item)"
            class="item"
            :class="{
              active: orders.find(order =>
                order.items.find(it => it.id === item.id)
              ),
              waiting: waiting.find(order => +order.id === +item.id)
            }"
          >
            <i
              v-if="
                orders.find(order => order.items.find(it => it.id === item.id))
              "
            ></i>
            <div class="grid">
              <h3>{{ item.title }}</h3>
              <img :src="replaceCoverImage(item.image)" alt="" />
              <p
                v-if="
                  orders.find(order =>
                    order.items.find(it => it.id === item.id)
                  )
                "
              >
                {{ $t("services.orderMade") }}
              </p>
              <p v-else>
                {{ item.subTitle }}
              </p>
              <button
                v-if="
                  orders.find(order =>
                    order.items.find(it => it.id === item.id)
                  )
                "
                @click.prevent="
                  cancelOrder({
                    id: item.id
                  })
                "
              >
                {{ $t("services.cancel") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import cover from "@/mixins/cover";
import flight from "@/mixins/flight";

// import RouteBar from "@/components/RouteBar";
import Slider from "@/components/Slider";
export default {
  name: "Services",
  components: { Slider },
  mixins: [cover, flight],
  title() {
    return this.$t("pages.services");
  },
  computed: {
    ...mapState({
      orders: state => state.order.index,
      waiting: state => state.order.waiting,
      ordersLoading: state => state.order.indexLoading,
      layout: state => state.order.layout,
      layoutLoading: state => state.order.layoutLoading,
      showModal: state => state.order.showModal,
      authenticate: state => state.user.authenticate,
      isUpgrade: state => state.flight.isUpgrade
    }),
    ...mapState({
      locale: state => state.locale
    }),
    backgroundHeaderImage() {
      return {
        backgroundImage: `url(${require("@/assets/" +
          this.headerItems[this.step].image)})`
      };
    },
    layoutComp() {
      if (!this.isUpgrade) {
        return this.layout.filter(item => item.category !== "upgradePlace");
      } else {
        return this.layout;
      }
    }
  },

  watch: {
    layoutLoading: {
      immediate: true,
      handler() {
        if (this.layoutLoading === "empty") {
          this.actionLayout();
        }
      }
    }
  },
  mounted() {
    this.actionLayout();
  },
  methods: {
    ...mapActions("order", {
      actionIndex: "index",
      setOrder: "setOrder",
      actionLayout: "layout",
      cancelOrder: "cancelOrder"
    }),
    ...mapMutations("order", {
      setEntity: "SET_ENTITY"
    }),
    closeModal() {
      this.setEntity(["showModal", false]);
    },
    setOrderAction(item) {
      if (item.category === "upgradePlace") {
        this.setEntity(["showModal", false]);
        if (this.$route.name !== "UpgradeSeat") {
          this.$router.push({ name: "UpgradeSeat" });
        }
      } else if (
        item.category === "service" &&
        !this.orders.find(order => order.items.find(it => it.id === item.id))
      ) {
        this.setOrder({
          id: +item.id
        });
      }
    }
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
</style>
